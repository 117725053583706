export const useSite = () => {
  const {
    public: { siteRegion }, // siteRegion 代表 site
  } = useRuntimeConfig()
  const isZa = computed(() => {
    return siteRegion === 'za'
  })
  const isSosoValue = computed(() => {
    return siteRegion === 'sosovalue'
  })
  return { isZa, isSosoValue }
}
